<template>
  <div class="md-layout md-alignment-center-center">
    <iframe scrolling="no" v-bind:src="dashboardUrl" frameborder="0" style="border:0" allowfullscreen ></iframe>
  </div>
</template>

<script>
import exhibitionList from '@/commons/exhibitionList'

export default {
  components: {
  },
  data() {
    return {
      exhibitionId: this.$route.params.exhibitionId ? this.$route.params.exhibitionId : 'exhibition_1',
      dashboardUrl: this.$route.params.exhibitionId ? exhibitionList[this.$route.params.exhibitionId] : 'https://lookerstudio.google.com/embed/reporting/b93acfaa-27e2-496e-9950-05cc002d45fd/page/sfgyD',
    };
  },
  watch: {
    '$route' (to, from) {
      const path = to.fullPath.split('/')[1];
      this.exhibitionId = path;
      this.dashboardUrl = exhibitionList[this.exhibitionId];
    }
  }
};
</script>

<style scoped>
iframe {
  width: 80%;
  height: 4000px;
}
.pancake, .mainBlock {
  height: 100% !important;
}
</style>