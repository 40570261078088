<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <!-- <user-menu></user-menu> -->
      <mobile-menu></mobile-menu>
      <template slot="links">
        <!-- <sidebar-item
          :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard', children:  }"
        /> -->
        <div class="dropdown-btn" id="dropdown-btn-1" @click="toggleMenu(1)">
          <img
            src="https://360hexaworld.blob.core.windows.net/assets/menu-down-white.png"
            id="dropdown-btn-img-1"
          />
          <div>
            청계천박물관
          </div>
        </div>
        <div
          class="dropdown-container"
          id="dropdown-container-1"
          style="display: flex;"
        >
          <a @click="moveUrl('exhibition_1')"> {{ exhibition_1 }} </a>
        </div>

        <div class="dropdown-btn" id="dropdown-btn-2" @click="toggleMenu(2)">
          <img
            src="https://360hexaworld.blob.core.windows.net/assets/menu-right-white.png"
            id="dropdown-btn-img-2"
          />
          <div>
            한양도성박물관
          </div>
        </div>
        <div class="dropdown-container" id="dropdown-container-2">
          <a @click="moveUrl('exhibition_2')">훈국, 도성을 쌓다</a>
        </div>

        <div class="dropdown-btn" id="dropdown-btn-3" @click="toggleMenu(3)">
          <img
            src="https://360hexaworld.blob.core.windows.net/assets/menu-right-white.png"
            id="dropdown-btn-img-3"
          />
          <div>
            공평도시유적전시관
          </div>
        </div>
        <div class="dropdown-container" id="dropdown-container-3">
          <a @click="moveUrl('exhibition_3')">우리가 만나던 그곳, 종로서적</a>
        </div>

        <div class="dropdown-btn" id="dropdown-btn-4" @click="toggleMenu(4)">
          <img
            src="https://360hexaworld.blob.core.windows.net/assets/menu-right-white.png"
            id="dropdown-btn-img-4"
          />
          <div>
            서울생활사박물관
          </div>
        </div>
        <div class="dropdown-container" id="dropdown-container-4">
          <a @click="moveUrl('exhibition_4')"
            >서울 외식이야기 - 오늘 뭐 먹지?</a
          >
        </div>

        <div class="dropdown-btn" id="dropdown-btn-5" @click="toggleMenu(5)">
          <img
            src="https://360hexaworld.blob.core.windows.net/assets/menu-right-white.png"
            id="dropdown-btn-img-5"
          />
          <div>
            서울역사박물관
          </div>
        </div>
        <div class="dropdown-container" id="dropdown-container-5">
          <a @click="moveUrl('exhibition_5')">동심, 기획전시</a>
        </div>

        <!-- <sidebar-item :link="{ name: 'KF Global Hall', icon: 'public', path: '/edit/lobby' }" />
        <sidebar-item :link="{ name: 'KF Meta Gallery', icon: 'collections', path: '/edit/gallery'}" />
        <sidebar-item :link="{ name: 'KF Conference Hall', icon: 'campaign', path: '/conference'}" />
        <sidebar-item
          :link="{
            name: 'KF Link',
            icon: 'link',
            path: '/edit/external-link',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Quest',
            icon: 'content_paste',
            path: '/quest',
          }"
        />
        <sidebar-item :link="{ name: 'Account', icon: 'account_circle', path: '/account'}" /> -->

        <!-- <sidebar-item
          :link="{
            name: 'table sample1',
            path: '/examples/user-management/list-users',
          }"
        />
        <sidebar-item
          :link="{
            name: 'table sample2',
            path: '/components/icons',
          }"
        />
        <sidebar-item
          :link="{
            name: 'table sample3',
            path: '/components/table',
          }"
        /> -->
      </template>
    </side-bar>

    <div class="main-panel">
      <!-- <top-navbar></top-navbar> -->

      <!-- <fixed-plugin
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin> -->

      <div :class="{ content: !$route.meta.hideContent }">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

// import TopNavbar from "./TopNavbar.vue";
// import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
// import FixedPlugin from "../../FixedPlugin.vue";
// import UserMenu from "./Extra/UserMenu.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    // TopNavbar,
    // ContentFooter,
    // FixedPlugin,
    MobileMenu,
    // UserMenu,
  },

  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage:
        process.env.VUE_APP_BASE_URL + "/img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
      image: process.env.VUE_APP_BASE_URL + "/img/laravel-vue.svg",
      exhibition_1: "<북둔도화北屯桃花: 성북천을 거닐다>",
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    toggleMenu(num) {
      // const button = document.getElementById(`dropdown-btn-${num}`);
      const container = document.getElementById(`dropdown-container-${num}`);
      const img = document.getElementById(`dropdown-btn-img-${num}`);

      if (container.style.display == "flex") {
        container.style.display = "none";
        // button.style.backgroundColor = 'transparent';
        img.src =
          "https://360hexaworld.blob.core.windows.net/assets/menu-right-white.png";
      } else {
        container.style.display = "flex";
        img.src =
          "https://360hexaworld.blob.core.windows.net/assets/menu-down-white.png";
        // button.style.backgroundColor = 'red';
      }
    },
    moveUrl(url) {
      if (this.$route.params.exhibitionId != url) {
        this.$router.push(`${url}`);
      }
      e.preventDefault();
    },
  },
  updated() {
    reinitScrollbar();
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  mounted() {
    // const token = this.getToken;
    // if(!token) {
    //     this.$router.push("/login")

    //     return;
    // }

    reinitScrollbar();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>

<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
.md-card-header {
  background-color: white !important;
}
.md-button-content {
  color: white;
}
.sidebar[data-color="green"] li.active > a:not([data-toggle="collapse"]),
.off-canvas-sidebar[data-color="green"]
  li.active
  > a:not([data-toggle="collapse"]) {
  background-color: rgb(250, 20, 47) !important;
}

.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Style the sidenav links and the dropdown button */
.sidenav a,
.dropdown-btn {
  display: flex;
  text-decoration: none;
  margin: 10px 15px 0;
  border-radius: 3px;
  white-space: nowrap;
  padding: 8px 10px;
  font-size: 13px;
  cursor: pointer;
  width: auto;
  background-color: transparent;
  user-select: none;
}

.sidenav a,
.dropdown-btn div {
  font-size: 16px;
  font-weight: 800;
  float: left;
  margin-right: 15px !important;
  margin-left: 0;
  line-height: 30px;
  margin: 0;
  text-align: center;
  color: white;
  cursor: pointer;
  transition: all;
  user-select: none;
}

.sidenav a,
.dropdown-btn img {
  max-width: 30px;
}

/* On mouse-over */
.dropdown-btn:hover {
  background-color: rgba(200, 200, 200, 0.2);
}

/* Main content */
.main {
  margin-left: 200px; /* Same as the width of the sidenav */
  font-size: 20px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

/* Add an active class to the active dropdown button */
.active {
  color: white;
  transition: all;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  padding-left: 8px;
  justify-content: flex-start;
  flex-direction: column;
}

.dropdown-container a {
  animation: growDown 300ms ease-in-out forwards;
  font-weight: 400;
}
.dropdown-container a:hover {
  background-color: rgba(200, 200, 200, 0.2);
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  80% {
    transform: scale(1.07);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.dropdown-container a {
  padding: 8px 10px;
  color: white !important;
  font-size: 12px;
  margin-left: 1rem;
  cursor: pointer;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}
</style>
