const exhibitionList = {
  exhibition_1:
    "https://lookerstudio.google.com/embed/reporting/b93acfaa-27e2-496e-9950-05cc002d45fd/page/sfgyD",
  exhibition_2:
    "https://lookerstudio.google.com/embed/reporting/aef66226-710d-4db0-a61e-c158d690286c/page/sfgyD",
  exhibition_3:
    "https://lookerstudio.google.com/embed/reporting/a66ff5aa-e5e5-4e07-b930-8c7bc7f2489d/page/sfgyD",
  exhibition_4:
    "https://lookerstudio.google.com/embed/reporting/8d4880c3-d03e-4c57-b625-84d9ec168f7f/page/sfgyD",
  exhibition_5:
    "https://lookerstudio.google.com/embed/reporting/916d46ea-7164-4d74-8af8-cc2aafb8597c/page/sfgyD",
  exhibition_6: "한양도성박물관",
  exhibition_7: "한양도성박물관",
  exhibition_8: "한양도성박물관",
  exhibition_9: "한양도성박물관",
  exhibition_10: "한양도성박물관",
  exhibition_11: "한양도성박물관",
};

export default exhibitionList;
